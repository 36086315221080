<template>
  <section class="promo">
    <HeaderPromo :caption="'Акции'" />

    <transition name="fade">
      <ChipsList class="mt-5" />
    </transition>

    <TopCtrlMenu v-if="$vuetify.breakpoint.mdAndUp" 
      class="pt-7"
      :elements="elements"
      :ctrlData="ctrlData" 
      :list="getPromoList"
      @delete="deleteItem"
      @clearElements="clearAllElements"
    />

    <TopCtrlMenuMobile v-else
      class="pt-7"
      :elements="elements"
      :ctrlData="ctrlData" 
      :list="getPromoList"
      @delete="deleteItem"
      @clearElements="clearAllElements"
    />

    <section v-if="$vuetify.breakpoint.mdAndUp" class="list mt-5">
      <draggable :list="getPromoList"
        v-bind="dragOptions"
        @start="startMove($event)"
        @end="endMove($event, getPromoList)" >
          <transition-group>
            <PromoListItem v-for="(item, i) in getPromoList" :key="item.id"
              :item="item" 
              :listItems="getPromoList"
              :listID="elements" 
              class="app-card"
              :class="{ 'app-card_last': i === getPromoList.length - 1 }"
              @addRemoveID="addRemoveOneEl" 
            />
          </transition-group>
      </draggable>
    </section>

    <section v-else class="list mt-5">
      <draggable :list="getPromoList"
        v-bind="dragOptions"
        @start="startMove($event)"
        @end="endMove($event, getPromoList)" >
          <transition-group>
            <PromoListItemMobile v-for="(item, i) in getPromoList" :key="item.id"
              :item="item" 
              :listItems="getPromoList"
              :listID="elements" 
              class="app-card"
              :class="{ 'app-card_last': i === getPromoList.length - 1 }"
              @addRemoveID="addRemoveOneEl" 
            />
          </transition-group>
      </draggable>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { generalPromo }           from '@/mixins/generalPromo'
import { mixinDraggable }         from '@/mixins/draggable'

import ChipsList from '@/components/promo/ChipsListPromo'
import HeaderPromo from '@/components/promo/HeaderPromo'
import TopCtrlMenu from '@/components/promo/TopCtrlMenu'
import PromoListItem from '@/components/promo/PromoListItem'

import TopCtrlMenuMobile from '@/components/promo/TopCtrlMenuMobile'
import PromoListItemMobile from '@/components/promo/PromoListItemMobile'

export default {
  name: 'PromoList',
  mixins: [generalPromo, mixinDraggable],
  components: { 
    ChipsList,
    HeaderPromo,
    TopCtrlMenu,
    PromoListItem,
    TopCtrlMenuMobile,
    PromoListItemMobile,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['getPromoList', 'getFilter']),
  },
  methods: {
    ...mapActions(['fetchPromoList', 'changeDates']),
    async deleteItem() {
      return false;
    },
  },
  async created() {
    await this.fetchPromoList();
  },
  watch: {
    'ctrlData.isAll': function() {
      if ( this.ctrlData.isAll === 3 ) {
        this.addAllElements(this.getPromoList)
      } else if ( !!!this.ctrlData.isAll ) {
        this.clearAllElements()
      }
    },
    async $route() {
      await this.fetchPromoList();
    }
  },
}
</script>

<style lang="scss" scoped>
.app-card {
  border-left: 1px solid #dadada !important;
  border-right: 1px solid #dadada !important;
  border-top: 1px solid #dadada !important;

  &_last {
    border-bottom: 1px solid #dadada !important;
  }

  .app-card__checkbox * {
    user-select: none;
  }
}
</style>
